import React from 'react'
import * as styles from './../styles/ServiceDetail.module.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import LayoutRoot from '../components/LayoutRoot'
import GreenCheck from '../icons/green_check.inline.svg'

interface ServiceDetailProps {
  pageContext: {
    service: Service
  }
}

const CheckItem: React.FC<{ item: string }> = ({ item }) => {
  const isLongContent = item.length > 22
  return (
    <div className={`${styles.checkItem} ${isLongContent && `${styles.checkItemLongText}`}`}>
      <div style={{ height: 19, width: 19 }}>
        <GreenCheck />
      </div>
      <div className={styles.checkItemText}>{item}</div>
    </div>
  )
}

const ServiceDetail: React.FC<ServiceDetailProps> = ({ pageContext: { service } }) => {
  const image = getImage(service.serviceCover.localFile)
  const advantages = service.advantages?.split('|')
  const requirements = service.requirements?.split('|')
  const steps = service.steps?.split('|')

  return (
    <LayoutRoot>
      <div className={styles.serviceDetailWrapper}>
        <div className={styles.serviceDetailBanner}>
          <div className={styles.bannerDarkerCover} />
          <GatsbyImage className={styles.bannerImageHolder} image={image} alt="background"></GatsbyImage>
          <div className={styles.bannerTitle}>
            <div className={styles.mainTitle}>{service.serviceName}</div>
            <div className={styles.subTitle}>{service.serviceEnglishName}</div>
          </div>
          <div className={styles.shortDescription}>{service.serviceShortDescription}</div>
        </div>
        {advantages && advantages.length > 0 && (
          <div className={styles.advantagesSection}>
            <div className={styles.sectionTitle}>项目优势</div>
            <div className={styles.advantagesList}>
              {advantages.map((advantage) => {
                return <CheckItem key={advantage} item={advantage} />
              })}
            </div>
          </div>
        )}
        {requirements && requirements.length > 0 && (
          <div className={styles.requirementSection}>
            <div className={styles.sectionTitle}>申请条件</div>
            {requirements.map((requirement) => {
              return <CheckItem key={requirement} item={requirement} />
            })}
          </div>
        )}
        {steps && steps.length > 0 && (
          <div className={styles.stepsSection}>
            <div className={styles.sectionTitle}>申请流程</div>
            <div className={styles.stepsList}>
              {steps.map((step, index) => {
                return (
                  <div className={styles.stepItem} key={step}>
                    <div className={styles.stepNumber}>{`Step${index + 1}`}</div>
                    <div className={styles.stepText}>{step}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </LayoutRoot>
  )
}

export default ServiceDetail
