// extracted by mini-css-extract-plugin
export var advantagesList = "ServiceDetail-module--advantagesList--bb3a9";
export var advantagesSection = "ServiceDetail-module--advantagesSection--3ed46";
export var bannerDarkerCover = "ServiceDetail-module--bannerDarkerCover--2286d";
export var bannerImageHolder = "ServiceDetail-module--bannerImageHolder--15db7";
export var bannerTitle = "ServiceDetail-module--bannerTitle--8093c";
export var checkItem = "ServiceDetail-module--checkItem--0d0a0";
export var checkItemLongText = "ServiceDetail-module--checkItemLongText--8455f";
export var checkItemText = "ServiceDetail-module--checkItemText--4d5a2";
export var mainTitle = "ServiceDetail-module--mainTitle--eb3b7";
export var requirementSection = "ServiceDetail-module--requirementSection--3f07e";
export var sectionTitle = "ServiceDetail-module--sectionTitle--b5852";
export var serviceDetailBanner = "ServiceDetail-module--serviceDetailBanner--23871";
export var serviceDetailWrapper = "ServiceDetail-module--serviceDetailWrapper--79078";
export var shortDescription = "ServiceDetail-module--shortDescription--372d6";
export var stepItem = "ServiceDetail-module--stepItem--64c03";
export var stepNumber = "ServiceDetail-module--stepNumber--f352a";
export var stepText = "ServiceDetail-module--stepText--a173e";
export var stepsList = "ServiceDetail-module--stepsList--d47f0";
export var stepsSection = "ServiceDetail-module--stepsSection--982aa";
export var subTitle = "ServiceDetail-module--subTitle--28d58";